// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Lato', Arial, sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$headings-font-family: 'Lato', Arial, sans-serif;

// ScopSERV Colors
$blue-one: #0068A5;
$blue-two: #1C1C37;
$blue-three: #E5E5E5;
$lightblue: #EEF2F8;
$lightgray: #E5E5E5;
$ssyellow: #F59C00;
$ssgreen: #73AC41;
$middlegray: #ACACAC;
$darkgray: #343434;
$orangered: #E26752;

// Overrides;
$blue: $blue-one;
$yellow: $ssyellow;
$green: $ssgreen;
$red: $orangered;

// Colors
$primary: #0068A5;
$success: $green;
$warning: $yellow;
$danger: $orangered;
$dark: $darkgray;
$light: $lightgray;
