// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons';

// General
body {
    font-size: 16px;
}

// Tables
table > thead > tr > th,
table > tbody > tr > td {
    vertical-align: middle;
}

// Alerts

// Buttons
.btn-danger {
    color: $white;
}

// Headings
.row.page-title {
    margin-bottom: $alert-margin-bottom;
}

h1 {
    font-weight: 300;
    font-size: 24px;
}

h2 {
    font-weight: 700;
    font-size: 18px;
}

.form-control-label {
    font-size: 17px;
    font-weight: 300;
}
